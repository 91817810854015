





























import Vue from 'vue'
import request from '@/utils/request'
import config from '@/utils/config'
interface DataTemp {
  id: string,
  areaData: any[],
  officeData: any[],
  formData: {
    name: string,
    areaId: string[],
    parentId: string,
  },
  rules: any,
}
export default Vue.extend({
  data(): DataTemp {
    return {
      id: '',
      areaData: [],
      officeData: [],
      formData: {
        name: '',
        areaId: [],
        parentId: '',
      },
      rules: {
        name: [{ required: true, message: '单位名称不能为空', trigger: 'blur' }],
        areaId: [{ required: true, type: 'array', message: '区域不能为空', trigger: 'change' }],
        parentId: [{ required: true, message: '上级不能为空', trigger: 'change' }],
      },
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.getData()
    const root: any = {
      value: config.defaultStationObj.adminCode,
      label: config.defaultStationObj.stationName,
      expand: false,
      loading: false,
      children: [],
    }
    this.loadAreaData(root)
    this.areaData.push(root)
    request.get(`/api/sys/office/operable_list`, null)
    .then((data) => {
      if (data && data.length > 0) {
        // 过滤当前部门
        this.officeData = data.filter((e: any) => e.id !== this.id)
      }
    })
  },
  methods: {
    loadAreaData(item: any) {
      const { value } = item
      request.get(`/api/sys/area/children/${value}`, null)
      .then((data) => {
        if (data) {
          item.children = this.buildTreeNode(data)
        }
      })
    },
    buildTreeNode(data: any) {
      if (!data || data.size === 0) {
        return []
      }
      const array: any[] = []
      data.forEach((e: any) => array.push(this.buildNode(e)))
      return array
    },
    buildNode(geoData: any) {
      const { id: value, name: label } = geoData
      const node = {value, label}
      return node
    },
    getData() {
      request.get(`/api/sys/office/${this.id}`)
      .then((data: any) => {
        this.formData = data
        if (data.areaId) {
          if (data.areaId === config.defaultStationObj.adminCode) {
            this.formData.areaId = [config.defaultStationObj.adminCode]
          } else {
            this.formData.areaId = [config.defaultStationObj.adminCode, data.areaId]
          }
        }
      })
    },
    submit() {
      const {name, areaId, parentId} = this.formData
      const dataForm: any = this.$refs.dataForm
      dataForm.validate((valid: boolean) => {
        if (valid) {
          request.put(`/api/sys/office/${this.id}`, {name, areaId: areaId[areaId.length - 1], parentId})
          .then((res) => {
            this.$Message.success('修改成功!')
            this.$router.replace('/sys_manage/office')
          })
        }
      })
    },
  },
})
